import React from "react";

import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}
export const OptionList = (props: React.PropsWithChildren<Props>) => {
  const { children, className, ...tails } = props;

  return (
    <div
      className={twMerge(
        "absolute z-50 flex translate-y-2 flex-col rounded-sm bg-white shadow-md ring-1 ring-inset ring-gray-300",
        className,
      )}
      {...tails}
    >
      {children}
    </div>
  );
};
