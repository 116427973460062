import React, { ReactNode } from "react";

import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  checked?: boolean;
  disabled?: boolean;
}
export const TabHorizontal: React.FC<Props> = (props) => {
  const { children, className, onClick, checked, ...tails } = props;

  return (
    <button
      className={twMerge(
        "flex items-center justify-center gap-2 py-4 disabled:opacity-30",
        checked && "border-b-2 border-b-primary-500 fill-primary-500 stroke-primary-500 pb-3.5 text-primary-500",
        className,
      )}
      onClick={props.disabled ? undefined : onClick}
      {...tails}
    >
      {children}
    </button>
  );
};
