import { PropsWithChildren } from "react";

import { RouterFunction } from "shared/RouterFunction.interface";

import { Sidebar, SidebarItem } from "components/Sidebar";

import { Base } from "./base";
import { UserInfo } from "./base.interface";

const _Sidebar = (props: RouterFunction) => {
  return (
    <Sidebar selected={props.location?.pathname}>
      <SidebarItem iid={"manage_dm"} title={<>DM 관리</>} header />
      <SidebarItem
        iid={"/dm/list"}
        title={<>DM 목록</>}
        onClick={() => {
          props.navigate?.("/dm/list");
        }}
      />
      <SidebarItem
        iid={"/dm/history"}
        title={<>히스토리</>}
        onClick={() => {
          props.navigate?.("/dm/history");
        }}
      />
    </Sidebar>
  );
};

export const DMLayoutView = (props: PropsWithChildren<UserInfo> & RouterFunction) => {
  return (
    <Base
      username={props.username}
      team={props.team}
      permission={props.permission}
      sidebar={<_Sidebar navigate={props.navigate} location={props.location} />}
    >
      {props.children}
    </Base>
  );
};
