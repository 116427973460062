import React, { PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  color?: string;
}
export const Link: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, onClick, ...tails } = props;

  const color = props.color || "primary";

  return (
    <button
      className={twMerge(
        "flex items-center justify-center gap-2",
        `fill-${color}-500 stroke-${color}-500 text-${color}-500`,
        `enabled:hover:fill-${color}-600 enabled:hover:stroke-${color}-600`,
        `enabled:hover:text-${color}-600 enabled:hover:underline`,
        `enabled:focus:fill-${color}-600 enabled:focus:stroke-${color}-600 enabled:focus:text-${color}-600`,
        "focus:outline-none disabled:opacity-30",
      )}
      onClick={props.disabled ? undefined : onClick}
      {...tails}
    >
      {children}
    </button>
  );
};
