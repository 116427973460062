import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as SVGSolidPencil } from "assets/icons/Solid/pencil.svg";
import { ReactComponent as SVGSolidTrash } from "assets/icons/Solid/trash.svg";
import { Button } from "components/Button";
import { PageHeader } from "components/PageHeader";

export const DetailLayoutView = (props: {
  info: { eid: string; name: string; dm: number | null; unresolved: boolean };
  permission: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const background = location.state?.background || location;

  return (
    <div className="flex flex-col gap-6 p-6 h-fit">
      {
        <PageHeader
          breadcrumbs={[
            { text: "장비 목록", ref: "/equipment/list" },
            {
              text: props.info.name,
              ref:
                location.pathname.includes("/dm") && !location.pathname.endsWith("/dm")
                  ? `/equipment/list/detail/${props.info.eid}`
                  : ".",
            },
          ]
            .concat(location.pathname.includes("/dm/edit") ? [{ text: "DM 목록 편집", ref: "#" }] : [])
            .map((e, i) => {
              return (
                <p key={i} onClick={() => navigate(e.ref)}>
                  {e.text}
                </p>
              );
            })}
          buttons={
            (props.permission &&
              location.pathname.includes("/equipment/list/detail/") &&
              !location.pathname.includes("/dm/edit") &&
              ["Super admin", "장비 권한"].includes(props.permission) && [
                <Button key={"edit"} className="p-m" secondary onClick={() => navigate(`../edit/${props.info.eid}`)}>
                  <SVGSolidPencil className="w-5 h-5" />
                  편집
                </Button>,
                <Button
                  key={"delete"}
                  className="p-m"
                  secondary
                  onClick={() => {
                    navigate(location.pathname.endsWith("/dm") ? "./dm/delete" : "./delete", {
                      replace: true,
                      state: {
                        background,
                        eid: props.info.eid,
                        warningStage: props.info.unresolved,
                      },
                    });
                  }}
                >
                  <SVGSolidTrash className="w-5 h-5" />
                  삭제
                </Button>,
              ]) ||
            undefined
          }
          tabs={
            props.info.dm !== null &&
            location.pathname.includes("/equipment/list/detail/") &&
            !location.pathname.includes("/dm/edit")
              ? ["장비 정보", "장비 DM"]
              : undefined
          }
          currentTab={props.info.dm !== null ? (location.pathname.includes(`${props.info.eid}/dm`) ? 1 : 0) : undefined}
          onTabChage={
            props.info.dm !== null && !location.pathname.endsWith("/dm/edit")
              ? (v) => {
                  v === 0 ? navigate(`../detail/${props.info.eid}`) : navigate("./dm");
                }
              : undefined
          }
        >
          {props.info.name}
        </PageHeader>
      }
      <Outlet />
    </div>
  );
};
