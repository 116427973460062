import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { sessionState } from "SessionRoute";

import {
  useDeviceFilterInit,
  useDeviceHistoryCurrentTabInit,
  useDeviceListCategoryInit,
  useDeviceListPagenationInit,
  useDeviceListSortInit,
  useDeviceMemoCategoryInit,
  useDeviceMemoPagenationInit,
  useDeviceMemoSortInit,
  useDeviceOwnerCategoryInit,
  useDeviceOwnerPagenationInit,
  useDeviceOwnerSortInit,
  useDevicePagenationInit,
  useDeviceSearchInit,
  useDeviceSelectedInit,
  useDeviceSortInit,
} from "recoil/device/hooks";

import { DeviceLayoutView } from "./DeviceLayoutView";

export const DeviceLayout = (props: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const session = useRecoilValue(sessionState);

  const resetDevicePagenation = useDevicePagenationInit();
  const resetDeviceFilter = useDeviceFilterInit();
  const resetDeviceSearch = useDeviceSearchInit();
  const resetDeviceSelected = useDeviceSelectedInit();
  const resetDeviceSort = useDeviceSortInit();

  const resetDeviceListCategory = useDeviceListCategoryInit();
  const resetDeviceOwnerCategory = useDeviceOwnerCategoryInit();
  const resetDeviceMemoCategory = useDeviceMemoCategoryInit();
  const resetDeviceHistoryTab = useDeviceHistoryCurrentTabInit();

  const resetDeviceListPagenation = useDeviceListPagenationInit();
  const resetDeviceOwnerPagenation = useDeviceOwnerPagenationInit();
  const resetDeviceMemoPagenation = useDeviceMemoPagenationInit();
  const resetDeviceListSort = useDeviceListSortInit();
  const resetDeviceOwnerSort = useDeviceOwnerSortInit();
  const resetDeviceMemoSort = useDeviceMemoSortInit();

  const deviceListInit = () => {
    resetDevicePagenation();
    resetDeviceFilter();
    resetDeviceSearch();
    resetDeviceSelected();
    resetDeviceSort();
  };

  const deviceHistoryInit = () => {
    resetDeviceListCategory();
    resetDeviceOwnerCategory();
    resetDeviceMemoCategory();
    resetDeviceHistoryTab();

    resetDeviceListPagenation();
    resetDeviceOwnerPagenation();
    resetDeviceMemoPagenation();

    resetDeviceListSort();
    resetDeviceOwnerSort();
    resetDeviceMemoSort();
  };

  return (
    <DeviceLayoutView
      username={[session?.meta.name].join(" ")}
      team={session?.team?.name || ""}
      permission={session?.permission?.name}
      navigate={navigate}
      location={location}
      deviceListInit={deviceListInit}
      deviceHistoryInit={deviceHistoryInit}
    >
      {props.children}
    </DeviceLayoutView>
  );
};
