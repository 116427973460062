import { lazy } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { SessionRoute, sessionState } from "SessionRoute";

import { SessionType } from "shared/Session.interface";

import { DeviceRoute } from "./DeviceRoute";
import { DMRoute } from "./DMRoute";
import { EquipRoute } from "./EquipRoute";
import { UserRoute } from "./UserRoute";

const SignIn = lazy(() => import("pages/user/signin").then((module) => ({ default: module.SignIn })));
const SignUp = lazy(() => import("pages/user/signup").then((module) => ({ default: module.SignUp })));

export const BaseRouter = () => {
  const location = useLocation();
  const background: Location | undefined = location.state && location.state.background;

  const session = useRecoilValue<SessionType | null | undefined>(sessionState);

  return (
    <Routes location={background ? location : undefined}>
      <Route path="/" element={<SessionRoute />}>
        {[
          <Route key="index" index element={<Navigate to="/device" />} />,
          ...DeviceRoute({ background }),
          ...EquipRoute({ background, session }),
          ...DMRoute({ background, session }),
          ...(import.meta.env.DEV ? UserRoute() : []),
        ]}
      </Route>
      <Route path="/signin" element={<SessionRoute signin />}>
        <Route index element={<SignIn />} />
      </Route>
      <Route path="/signup" element={<SessionRoute signup />}>
        <Route index element={<SignUp />} />
      </Route>
    </Routes>
  );
};
