import { twMerge } from "tailwind-merge";

import { ToastFieldProps } from "./Toast.interface";
import { ToastProvider, useToastStateContext } from "./ToastProvider";

export const ToastField = (props: ToastFieldProps) => {
  return (
    <ToastProvider>
      <Consumer className={props.className}>{props.children}</Consumer>
    </ToastProvider>
  );
};

const Consumer = (props: ToastFieldProps) => {
  const { className, children, ...etcs } = props;
  const toasts = useToastStateContext();

  return (
    <>
      <div className="flex justify-center">
        <div
          id="toastfield"
          className={twMerge(
            "pointer-events-none select-none",
            "fixed",
            "flex flex-col-reverse justify-center",
            "z-[15000]",
            "bottom-[12.5rem] gap-2",
            className,
          )}
          {...etcs}
        >
          {toasts}
        </div>
      </div>
      {children}
    </>
  );
};
