import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { sessionState } from "SessionRoute";

import { EquipmentLayoutView } from "./EquipmentLayoutView";

export const EquipmentLayout = (props: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const session = useRecoilValue(sessionState);

  return (
    <EquipmentLayoutView
      username={session?.meta.name || ""}
      team={session?.team?.name || ""}
      permission={session?.permission?.name}
      navigate={navigate}
      location={location}
    >
      {props.children}
    </EquipmentLayoutView>
  );
};
