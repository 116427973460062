import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { EquipmentLayout } from "layouts";

import { SessionType } from "shared/Session.interface";

import { DetailLayout } from "pages/equipment/list/detail/DetailLayout";

const EquipmentHistory = lazy(() => import("pages/equipment/history").then((module) => ({ default: module.History })));
const EquipmentList = lazy(() => import("pages/equipment/list").then((module) => ({ default: module.List })));
const EquipmentDetail = lazy(() =>
  import("pages/equipment/list/detail").then((module) => ({ default: module.Detail })),
);
const AddIssueModal = lazy(() =>
  import("pages/equipment/list/detail/AddIssueModal").then((module) => ({
    default: module.AddIssueModal,
  })),
);
const EquipmentDelete = lazy(() =>
  import("pages/equipment/list/detail/DeleteModal").then((module) => ({ default: module.DeleteModal })),
);
const EquipmentDM = lazy(() => import("pages/equipment/list/detail/dm").then((module) => ({ default: module.DM })));
const DMEdit = lazy(() => import("pages/equipment/list/detail/dm/edit").then((module) => ({ default: module.DMEdit })));
const DMEditCancel = lazy(() =>
  import("pages/equipment/list/detail/dm/edit/CancelModal").then((module) => ({ default: module.CancelModal })),
);
const DMEditConfirm = lazy(() =>
  import("pages/equipment/list/detail/dm/edit/ConfirmModal").then((module) => ({ default: module.ConfirmModal })),
);
const DMEditDelete = lazy(() =>
  import("pages/equipment/list/detail/dm/edit/DeleteModal").then((module) => ({ default: module.DeleteModal })),
);
const DMAdd = lazy(() =>
  import("pages/equipment/list/detail/dm/edit/add").then((module) => ({ default: module.DMAdd })),
);
const IssueModal = lazy(() =>
  import("pages/equipment/list/detail/IssueModal").then((module) => ({ default: module.IssueModal })),
);
const EquipmentEdit = lazy(() => import("pages/equipment/list/edit").then((module) => ({ default: module.Edit })));
const EquipmentEditConfirm = lazy(() =>
  import("pages/equipment/list/edit/ConfirmModal/ConfirmModal").then((module) => ({ default: module.ConfirmModal })),
);
const IPModal = lazy(() => import("pages/equipment/list/IPModal").then((module) => ({ default: module.IPModal })));
const EquipmentRegister = lazy(() =>
  import("pages/equipment/list/register").then((module) => ({ default: module.Register })),
);
const EquipmentRegisterConfirm = lazy(() =>
  import("pages/equipment/list/register/ConfirmModal").then((module) => ({
    default: module.ConfirmModal,
  })),
);

export const EquipRoute = (props: { background?: Location; session: SessionType | null | undefined }) => {
  return [
    <Route
      key="equipment"
      path="/equipment"
      element={
        <EquipmentLayout>
          <Outlet />
        </EquipmentLayout>
      }
    >
      <Route index element={<Navigate to="list" replace />} />
      <Route path="list">
        <Route
          index
          element={
            <Suspense>
              <EquipmentList />
            </Suspense>
          }
        />
        <Route path="register" element={<EquipmentRegister />}>
          {props.background && <Route path="confirm" element={<EquipmentRegisterConfirm />} />}
          <Route path="*" element={<Navigate to="../" replace />} />
        </Route>
        <Route path="detail/:id">
          <Route element={<DetailLayout />}>
            <Route path="" element={<EquipmentDetail />}>
              {props.background && <Route path="ip" element={<IPModal />} />}
              {props.background && <Route path="delete" element={<EquipmentDelete />} />}
              {props.background && (
                <Route path="issue">
                  <Route path="add" element={<AddIssueModal />} />
                  <Route path=":iid" element={<IssueModal permission={props.session.permission?.name} />} />
                </Route>
              )}
              <Route path="*" element={<Navigate to="../" replace />} />
            </Route>
            <Route path="dm">
              <Route path="" element={<EquipmentDM />}>
                {props.background && <Route path="delete" element={<EquipmentDelete />} />}
              </Route>
              <Route path="edit" element={<DMEdit />}>
                {props.background && <Route path="add" element={<DMAdd />} />}
                {props.background && <Route path="cancel" element={<DMEditCancel />} />}
                {props.background && <Route path="confirm" element={<DMEditConfirm />} />}
                {props.background && <Route path="delete" element={<DMEditDelete />} />}
                <Route path="*" element={<Navigate to="../" replace />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="../" replace />} />
          </Route>
        </Route>
        <Route path="edit/:id" element={<EquipmentEdit />}>
          {props.background && <Route path="confirm" element={<EquipmentEditConfirm />} />}
          {props.background && <Route path="ip" element={<IPModal />} />}
          <Route path="*" element={<Navigate to="../" replace />} />
        </Route>
      </Route>
      <Route path="history" element={<EquipmentHistory />} />
    </Route>,
  ];
};
