import React from "react";

import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGSpinnerLG } from "assets/icons/Outline/spinner-lg.svg";
import { ReactComponent as SVGSpinnerMD } from "assets/icons/Outline/spinner-md.svg";
import { ReactComponent as SVGSpinnerSM } from "assets/icons/Outline/spinner-sm.svg";

interface Props {
  className?: string;
  label?: string;
  size?: string;
}

export const Spinner: React.FC<Props> = (props) => {
  const { className, label, size, ...etcs } = props;

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <div className={twMerge("flex items-center justify-center", className)} {...etcs}>
        {(size === "sm" && <SVGSpinnerSM className="h-5 w-5 fill-primary-500 stroke-gray-300" />) ||
          (size === "md" && <SVGSpinnerMD className="h-12 w-12 fill-primary-500 stroke-gray-300" />) ||
          (size === "lg" && <SVGSpinnerLG className="h-20 w-20 fill-primary-500 stroke-gray-300" />)}
      </div>
      {label ? label : null}
    </div>
  );
};

Spinner.defaultProps = {
  size: "sm",
};
