import React, { ReactElement, ReactNode, useState } from "react";

import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGSolidCheveronDown } from "assets/icons/Solid/cheveron-down.svg";
import { Props as ItemProps } from "components/Select/Option";
import { OptionList } from "components/Select/OptionList";

interface Props {
  children?: ReactElement<ItemProps>[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: ReactNode;
  selected?: boolean;
  disabled?: boolean;
}
export const HeaderLink: React.FC<Props> = (props) => {
  const { children, label, selected, onClick, ...tails } = props;

  const [open, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState<number | undefined>();

  return (
    <div className="relative">
      <button
        className={twMerge(
          "flex flex-col items-center px-4 enabled:hover:text-primary-500 disabled:opacity-30",
          selected && "text-primary-500",
        )}
        onClick={(ev) => {
          setOpen(!open);
          onClick?.(ev);
        }}
        {...tails}
      >
        <div className={twMerge("flex gap-2 py-[1.375rem]", selected && "border-b-2 border-b-primary-500 pb-5")}>
          {label}
          {children && <SVGSolidCheveronDown className="h-5 w-5" />}
        </div>
      </button>
      {!props.disabled && open && children && (
        <OptionList className="mx-4">
          {children.map((e, i) =>
            React.cloneElement<ItemProps>(e, {
              ...e.props,
              checked: selectedItem === i,
              onClick: async (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                setOpen(false);
                setSelectedItem(i);
                e.props.onClick?.(ev);
              },
            }),
          )}
        </OptionList>
      )}
    </div>
  );
};
