import React, { ReactNode, useState } from "react";

import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGSolidCoG } from "assets/icons/Solid/cog.svg";
import { ReactComponent as SVGSolidMenu } from "assets/icons/Solid/menu.svg";
import { Button } from "components/Button";
import { SettingMenu } from "components/SettingMenu";

export interface Props {
  className?: string;
  branding?: ReactNode;
  children?: ReactNode;
  sidebar?: boolean;
  onClickSidebar?: React.MouseEventHandler<HTMLButtonElement>;
  username: string;
  team: string;
  permission?: string;
  settingMenu?: ReactNode;
}
export const GlobalNavigation: React.FC<Props> = (props) => {
  const [openSetting, setOpenSetting] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 z-[1000] h-16 w-full whitespace-nowrap bg-white shadow-base">
      <div className={twMerge("relative mx-auto flex items-center scrollbar-hide", props.className)}>
        <div className="flex flex-1 gap-4">
          <div className="flex items-center gap-2 px-4">
            {props.sidebar && (
              <Button tertiary className="p-2" onClick={props.onClickSidebar}>
                <SVGSolidMenu className="h-5 w-5" />
              </Button>
            )}
            {props.branding}
          </div>
          <div className="flex flex-1">{props.children}</div>
        </div>
        <div className="flex items-center gap-2 px-4">
          {props.username && <button className="text-gray-500">{props.username}</button>}
          <div
            className="relative flex flex-col items-end"
            tabIndex={-1}
            onBlur={(evt: React.FocusEvent) => {
              if (!evt.currentTarget.contains(evt.relatedTarget)) {
                setOpenSetting(false);
              }
            }}
          >
            <Button
              tertiary
              className="p-2"
              selected={openSetting}
              onClick={() => {
                setOpenSetting((prev) => !prev);
              }}
            >
              <SVGSolidCoG className="h-5 w-5" />
            </Button>
            {openSetting && (
              <div>
                <SettingMenu
                  className="w-fit -translate-x-full"
                  username={props.username}
                  team={props.team}
                  permission={props.permission}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
