import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import {
  deviceFilterState,
  deviceHistoryCurrentTabState,
  deviceListCategoryState,
  deviceListPagenationState,
  deviceListSortState,
  deviceMemoCategoryState,
  deviceMemoPagenationState,
  deviceMemoSortState,
  deviceOwnerCategoryState,
  deviceOwnerPagenationState,
  deviceOwnerSortState,
  devicePagenationState,
  deviceSearchState,
  deviceSelectedState,
  deviceSortState,
} from "./atoms";

export const useDevicePagenationValue = () => {
  const { perPage, current } = useRecoilValue(devicePagenationState);

  return { perPage, current, offset: (current - 1) * perPage };
};

export const useDevicePagenation = () => {
  return useRecoilState(devicePagenationState);
};

export const useDevicePagenationSetter = () => {
  return useSetRecoilState(devicePagenationState);
};

export const useDeviceFilterValue = () => {
  return useRecoilValue(deviceFilterState);
};

export const useDeviceFilter = () => {
  return useRecoilState(deviceFilterState);
};

export const useDeviceFilterSetter = () => {
  return useSetRecoilState(deviceFilterState);
};

export const useDeviceSearchValue = () => {
  return useRecoilValue(deviceSearchState);
};

export const useDeviceSearch = () => {
  return useRecoilState(deviceSearchState);
};

export const useDeviceSearchSetter = () => {
  return useSetRecoilState(deviceSearchState);
};

export const useDeviceSortValue = () => {
  return useRecoilValue(deviceSortState);
};

export const useDeviceSort = () => {
  return useRecoilState(deviceSortState);
};

export const useDeviceSortSetter = () => {
  return useSetRecoilState(deviceSortState);
};

export const useDeviceSelectedValue = () => {
  return useRecoilValue(deviceSelectedState);
};

export const useDeviceSelected = () => {
  return useRecoilState(deviceSelectedState);
};

export const useDeviceSelectedSetter = () => {
  return useSetRecoilState(deviceSelectedState);
};

export const useDevicePagenationInit = () => {
  return useResetRecoilState(devicePagenationState);
};

export const useDeviceSearchInit = () => {
  return useResetRecoilState(deviceSearchState);
};

export const useDeviceFilterInit = () => {
  return useResetRecoilState(deviceFilterState);
};
export const useDeviceSelectedInit = () => {
  return useResetRecoilState(deviceSelectedState);
};

export const useDeviceSortInit = () => {
  return useResetRecoilState(deviceSortState);
};

export const useDeviceListCategory = () => {
  return useRecoilState(deviceListCategoryState);
};

export const useDeviceOwnerCategory = () => {
  return useRecoilState(deviceOwnerCategoryState);
};

export const useDeviceMemoCategory = () => {
  return useRecoilState(deviceMemoCategoryState);
};

export const useDeviceListCategoryInit = () => {
  return useResetRecoilState(deviceListCategoryState);
};

export const useDeviceOwnerCategoryInit = () => {
  return useResetRecoilState(deviceOwnerCategoryState);
};

export const useDeviceMemoCategoryInit = () => {
  return useResetRecoilState(deviceMemoCategoryState);
};

export const useDeviceHistoryCurrentTab = () => {
  return useRecoilState(deviceHistoryCurrentTabState);
};

export const useDeviceHistoryCurrentTabInit = () => {
  return useResetRecoilState(deviceHistoryCurrentTabState);
};

export const useDeviceListPagenation = () => {
  return useRecoilState(deviceListPagenationState);
};

export const useDeviceOwnerPagenation = () => {
  return useRecoilState(deviceOwnerPagenationState);
};

export const useDeviceMemoPagenation = () => {
  return useRecoilState(deviceMemoPagenationState);
};

export const useDeviceListSort = () => {
  return useRecoilState(deviceListSortState);
};

export const useDeviceOwnerSort = () => {
  return useRecoilState(deviceOwnerSortState);
};

export const useDeviceMemoSort = () => {
  return useRecoilState(deviceMemoSortState);
};

export const useDeviceListPagenationInit = () => {
  return useResetRecoilState(deviceListPagenationState);
};

export const useDeviceOwnerPagenationInit = () => {
  return useResetRecoilState(deviceOwnerPagenationState);
};

export const useDeviceMemoPagenationInit = () => {
  return useResetRecoilState(deviceMemoPagenationState);
};

export const useDeviceListSortInit = () => {
  return useResetRecoilState(deviceListSortState);
};

export const useDeviceOwnerSortInit = () => {
  return useResetRecoilState(deviceOwnerSortState);
};

export const useDeviceMemoSortInit = () => {
  return useResetRecoilState(deviceMemoSortState);
};
