import { lazy } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { DeviceLayout } from "layouts/DeviceLayout";

const DeviceHistory = lazy(() => import("pages/device/history").then((module) => ({ default: module.History })));
const DeviceList = lazy(() => import("pages/device/list").then((module) => ({ default: module.List })));
const BulkEdit = lazy(() => import("pages/device/list/bulk").then((module) => ({ default: module.BulkEdit })));
const BulkEditConfirmModal = lazy(() =>
  import("pages/device/list/bulk/ConfirmModal").then((module) => ({ default: module.ConfirmModal })),
);
const DeviceDetail = lazy(() => import("pages/device/list/detail").then((module) => ({ default: module.Detail })));
const DeviceDeleteModal = lazy(() =>
  import("pages/device/list/detail/DeleteModal").then((module) => ({ default: module.DeleteModal })),
);
const MemoDeleteModal = lazy(() =>
  import("pages/device/list/detail/Memo/MemoDeleteModal").then((module) => ({ default: module.MemoDeleteModal })),
);
const MemoDrawer = lazy(() =>
  import("pages/device/list/detail/Memo/MemoDrawer").then((module) => ({ default: module.MemoDrawer })),
);
const DeviceEdit = lazy(() => import("pages/device/list/edit").then((module) => ({ default: module.Edit })));
const EditConfirmModal = lazy(() =>
  import("pages/device/list/edit/ConfirmModal").then((module) => ({ default: module.ConfirmModal })),
);
const DeviceRegister = lazy(() =>
  import("pages/device/list/register").then((module) => ({ default: module.Register })),
);
const Excel = lazy(() => import("pages/device/list/register/bulk/excel").then((module) => ({ default: module.Excel })));
const Form = lazy(() => import("pages/device/list/register/bulk/form").then((module) => ({ default: module.Form })));
const Result = lazy(() =>
  import("pages/device/list/register/bulk/result").then((module) => ({ default: module.Result })),
);
const RegisterErrorModal = lazy(() =>
  import("pages/device/list/register/ErrorModal").then((module) => ({ default: module.ErrorModal })),
);
const RegisterModal = lazy(() =>
  import("pages/device/list/register/RegisterModal").then((module) => ({ default: module.RegisterModal })),
);
const TransferForm = lazy(() => import("pages/device/transfer/bulk/form").then((module) => ({ default: module.Form })));
const TransferExcel = lazy(() =>
  import("pages/device/transfer/bulk/excel").then((module) => ({ default: module.Excel })),
);
const TransferResult = lazy(() =>
  import("pages/device/transfer/bulk/result").then((module) => ({ default: module.Result })),
);
const DeviceTransferModal = lazy(() =>
  import("pages/device/list/detail/TransferModal").then((module) => ({ default: module.TransferModal })),
);
const DeviceDownloadModal = lazy(() =>
  import("pages/device/list/DownloadModal").then((module) => ({ default: module.DownloadModal })),
);

export const DeviceRoute = (props: { background?: Location }) => {
  return [
    <Route
      key="device"
      path="/device"
      element={
        <DeviceLayout>
          <Outlet />
        </DeviceLayout>
      }
    >
      <Route index element={<Navigate to="list" replace />} />
      <Route path="list">
        <Route path="" element={<DeviceList />}>
          {props.background && <Route path="download" element={<DeviceDownloadModal />} />}
        </Route>
        <Route path="register">
          <Route path="" element={<DeviceRegister />}>
            {props.background && <Route path="confirm" element={<RegisterModal />} />}
            {props.background && <Route path="error" element={<RegisterErrorModal />} />}
            <Route path="*" element={<Navigate to={".."} replace />} />
          </Route>
          <Route path="bulk">
            <Route path="form" element={<Form />} />
            <Route path="excel" element={<Excel />} />
            <Route path="result" element={<Result />} />
          </Route>
        </Route>
        <Route path="detail/:sn" element={<DeviceDetail />}>
          {props.background && <Route path="delete" element={<DeviceDeleteModal />} />}
          {props.background && <Route path="transfer" element={<DeviceTransferModal />} />}
          {props.background && (
            <Route path="memo" element={<MemoDrawer />}>
              <Route path="delete" element={<MemoDeleteModal />} />
            </Route>
          )}
          <Route path="*" element={<Navigate to={".."} replace />} />
        </Route>
        <Route path="edit/:sn" element={<DeviceEdit />}>
          {props.background && <Route path="confirm" element={<EditConfirmModal />} />}
          <Route path="*" element={<Navigate to={".."} replace />} />
        </Route>
        <Route path="bulk">
          <Route path="edit" element={<BulkEdit />}>
            {props.background && <Route path="confirm" element={<BulkEditConfirmModal />} />}
          </Route>
        </Route>
      </Route>
      <Route path="transfer">
        <Route path="bulk">
          <Route path="form" element={<TransferForm />} />
          <Route path="excel" element={<TransferExcel />} />
          <Route path="result" element={<TransferResult />} />
        </Route>
      </Route>
      <Route path="history" element={<DeviceHistory />} />
    </Route>,
  ];
};
