import React from "react";
import ReactDOM from "react-dom/client";

import "@fontsource-variable/noto-sans-kr";
import "@fontsource-variable/noto-sans-mono";

import { auth } from "services/entraid";

import App from "./App";
import "./index.css";
import reportWebVitals from "./libs/reportWebVitals";

window.addEventListener("load", async () => {
  auth.loadAuthModule();
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
reportWebVitals();
