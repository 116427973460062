import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { sessionState } from "SessionRoute";

import { DMLayoutView } from "./DMLayoutView";

export const DMLayout = (props: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const session = useRecoilValue(sessionState);

  return (
    <DMLayoutView
      username={session?.meta.name || ""}
      team={session?.team?.name || ""}
      permission={session?.permission?.name}
      navigate={navigate}
      location={location}
    >
      {props.children}
    </DMLayoutView>
  );
};
