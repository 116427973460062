import React, { ReactElement, ReactNode, useState } from "react";

import { Breadcrumbs } from "components/Breadcrumbs";
import { TabHorizontal } from "components/TabHorizontal";

interface Props {
  children: ReactNode;
  buttons?: ReactElement[];
  breadcrumbs?: ReactElement[];
  tabs?: string[];
  currentTab?: number;
  onTabChage?: (idx: number) => void;
}
export const PageHeader: React.FC<Props> = (props) => {
  const { children, buttons, breadcrumbs, tabs } = props;

  const [currentTab, setCurrentTab] = useState(props.currentTab || 0);

  return (
    <div className="flex w-full flex-col gap-1.5">
      {breadcrumbs && <Breadcrumbs>{breadcrumbs}</Breadcrumbs>}
      <div className="flex justify-between">
        <div className="text-2xl font-medium">{children}</div>
        {buttons && <div className="flex gap-2">{buttons.map((e) => e)}</div>}
      </div>
      {tabs && (
        <div className="mb-px flex items-start gap-8 border-b border-b-gray-300">
          {tabs.map((e, i) => (
            <TabHorizontal
              key={i}
              className="-mb-px"
              onClick={() => {
                setCurrentTab(i);
                props.onTabChage?.(i);
              }}
              checked={currentTab === i}
            >
              {e}
            </TabHorizontal>
          ))}
        </div>
      )}
    </div>
  );
};
