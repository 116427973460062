import { createContext, PropsWithChildren, ReactElement, useContext, useReducer } from "react";

import uniqueId from "lodash/uniqueId";

import { ToastActionType, ToastItemProps } from "./Toast.interface";
import { ToastItem } from "./ToastItem";

const ToastStateContext = createContext<ReactElement<ToastItemProps>[]>([]);
const ToastDispatchContext = createContext<React.Dispatch<ToastActionType> | null>(null);

export const useToastStateContext = () => {
  const context = useContext(ToastStateContext);

  if (!context) {
    throw new Error("ToastStateContext must be inside ToastStateContextProvider");
  }

  return context;
};

export const useToastDispatchContext = () => {
  const context = useContext(ToastDispatchContext);

  if (!context) {
    throw new Error("ToastDispatchContext must be inside ToastDispatchProvider");
  }

  return context;
};

export const ToastProvider = (props: PropsWithChildren) => {
  const [toasts, dispatch] = useReducer(toastReducer, []);

  return (
    <ToastDispatchContext.Provider value={dispatch}>
      <ToastStateContext.Provider value={toasts}>{props.children}</ToastStateContext.Provider>
    </ToastDispatchContext.Provider>
  );
};

const toastReducer = (state: ReactElement<ToastItemProps>[], action: ToastActionType) => {
  switch (action.type) {
    case "PUSH_TOAST": {
      const now = new Date().getTime();
      state = state.filter((e) => e?.props.ttl > now);

      if (state.length >= 5) {
        state = state.slice(0, 4);
      }

      return [
        <ToastItem key={uniqueId("toast")} ttl={new Date().getTime() + 7000}>
          {action.payload}
        </ToastItem>,
        ...state,
      ];
    }
    default:
      return state;
  }
};
