import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGCheckCircle } from "assets/icons/Solid/check-circle.svg";

import { ToastItemProps } from "./Toast.interface";

export const ToastItem = (props: ToastItemProps) => {
  const { className, children, ...etcs } = props;

  return (
    <div
      className={twMerge(
        "flex",
        "gap-2 px-4 py-3",
        "rounded-sm bg-gray-700 shadow-xl",
        "fill-white text-white",
        className,
      )}
      {...etcs}
    >
      <SVGCheckCircle className="h-5 w-5" />
      {children}
    </div>
  );
};
