/**
 * @generated SignedSource<<f388346dfacb79b353417fc8e7079855>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SessionRouteUserQuery$variables = {};
export type SessionRouteUserQuery$data = {
  readonly user230227: {
    readonly id: string;
    readonly meta: {
      readonly name: string;
    };
    readonly orgs: ReadonlyArray<{
      readonly id: string;
      readonly name: ReadonlyArray<string>;
    }>;
    readonly permissions: ReadonlyArray<{
      readonly name: string;
    }>;
  } | null;
};
export type SessionRouteUserQuery = {
  response: SessionRouteUserQuery$data;
  variables: SessionRouteUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserMetaType",
  "kind": "LinkedField",
  "name": "meta",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Org",
  "kind": "LinkedField",
  "name": "orgs",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SessionRouteUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User230227",
        "kind": "LinkedField",
        "name": "user230227",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Permission230227",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SessionRouteUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User230227",
        "kind": "LinkedField",
        "name": "user230227",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Permission230227",
            "kind": "LinkedField",
            "name": "permissions",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad0aa0abc38101112b344e02e08d4f1e",
    "id": null,
    "metadata": {},
    "name": "SessionRouteUserQuery",
    "operationKind": "query",
    "text": "query SessionRouteUserQuery {\n  user230227 {\n    id\n    meta {\n      name\n    }\n    orgs {\n      id\n      name\n    }\n    permissions {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e08ba7712c01a8561a125876889ae1e6";

export default node;
