import React, { cloneElement, ReactElement } from "react";

import { twMerge } from "tailwind-merge";

import type { Props as ItemProps } from "./Item";

export { SidebarItem } from "./Item";
export type { Props as ItemProps } from "./Item";

interface Props {
  children?: ReactElement<ItemProps>[];
  className?: string;
  selected?: string;
}
export const Sidebar = (props: Props) => {
  const { children, className, selected, ...tails } = props;

  return (
    <div className={twMerge("flex h-full w-full shrink-0 flex-col", className)} {...tails}>
      {children?.map((e) => {
        const { iid } = e.props;
        return cloneElement(e, {
          ...e.props,
          iid,
          key: iid,
          selected,
          onClick: async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.props.onClick?.(evt);
          },
        });
      })}
    </div>
  );
};
