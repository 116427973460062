import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  LogLevel,
  PopupRequest,
  PublicClientApplication,
} from "@azure/msal-browser";

import entraidConfig from "./config.json";

const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: entraidConfig.client_id,
    authority: `https://login.microsoftonline.com/${entraidConfig.tenant_id}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: import.meta.env.PROD
    ? undefined
    : {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
        },
      },
};

class Auth {
  private MSAL: PublicClientApplication = new PublicClientApplication(MSAL_CONFIG);
  private refresher?: NodeJS.Timeout = undefined;
  private async refreshAccount(account?: AccountInfo) {
    clearTimeout(this.refresher);
    if (account) {
      this.refresher = setTimeout(async () => {
        await this.MSAL.acquireTokenSilent({
          ...this.loginRequest,
          account,
        }).then((result) => this.refreshAccount(result.account));
      }, (account?.idTokenClaims?.exp ?? 0) * 1e3 - +new Date() - 5e3);
    }
    return account;
  }
  private loginRequest: PopupRequest = {
    scopes: [],
  };

  private authStateEventTarget: EventTarget = new EventTarget();

  onAuthStateChanged(callback: EventListenerOrEventListenerObject | null): void {
    this.authStateEventTarget.addEventListener("update", callback);
    callback(undefined);
  }

  async getAccount(): Promise<AccountInfo | null | undefined> {
    const currentAccounts = this.MSAL.getAllAccounts();
    if (currentAccounts === null) {
      return null;
    }

    return this.refreshAccount(currentAccounts.at(0));
  }

  private handleResponse(response: AuthenticationResult | null) {
    this.authStateEventTarget.dispatchEvent(new Event("update"));
    return response?.account || this.getAccount();
  }

  loadAuthModule(): void {
    this.MSAL.initialize().then(() => {
      this.MSAL.handleRedirectPromise()
        .then((resp: AuthenticationResult | null) => {
          this.handleResponse(resp);
        })
        .catch(console.error);
    });
  }

  signInWithPopup() {
    return new Promise<AccountInfo | null | undefined>((resolve, reject) =>
      this.MSAL.loginPopup(this.loginRequest)
        .then((resp: AuthenticationResult) => this.handleResponse(resp))
        .then(resolve)
        .catch(reject),
    );
  }

  async signOut() {
    return this.MSAL.logoutRedirect({
      account: await this.getAccount(),
    });
  }
}

export const auth = new Auth();
