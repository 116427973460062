import { GraphQLResponse, Variables } from "relay-runtime";

import { auth } from "services/entraid";

async function fetchGraphQL(text: string | null, variables: Variables): Promise<GraphQLResponse> {
  return auth
    .getAccount()
    .then((account) => ({
      Authorization: `Bearer ${account!.idToken}`,
    }))
    .then(async (headers) => {
      const response = await fetch(
        "" + (import.meta.env.PROD ? import.meta.env.VITE_APP_PROD_API_URL : import.meta.env.VITE_APP_DEV_API_URL),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify({
            query: text,
            variables,
          }),
        },
      );

      return await response.json();
    });
}

export default fetchGraphQL;
