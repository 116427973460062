import React, { ReactElement } from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { BrowserRouter } from "react-router-dom";

import { RecoilRoot } from "recoil";
import { BaseRouter } from "routes";

import { Spinner } from "components/Spinner";
import { ToastField } from "components/Toast";
import RelayEnvironment from "libs/RelayEnvironment";

const { Suspense } = React;

function AppRoot(): ReactElement {
  return (
    <RecoilRoot>
      <ToastField>
        <Suspense
          fallback={
            <div className="flex h-screen w-screen flex-col items-center justify-center">
              <Spinner size="lg" />
            </div>
          }
        >
          <RelayEnvironmentProvider environment={RelayEnvironment}>
            <BrowserRouter>
              <BaseRouter />
            </BrowserRouter>
          </RelayEnvironmentProvider>
        </Suspense>
      </ToastField>
    </RecoilRoot>
  );
}

export default AppRoot;
