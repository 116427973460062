import React, { createRef, ReactElement, useEffect, useState } from "react";

import { ReactComponent as SVGSolidDotsHorizontal2 } from "assets/icons/Solid/dots-horizontal2.svg";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { Option } from "components/Select";
import { OptionList } from "components/Select/OptionList";

interface Props {
  children: ReactElement[];
  separator?: ReactElement;
  width?: number;
}
export const Breadcrumbs: React.FC<Props> = (props) => {
  const { children } = props;
  const separator = props.separator || "/";
  const width = props.width || 816;

  const ref = createRef<HTMLDivElement>();
  const lastItemRef = createRef<HTMLButtonElement>();

  const [isOverflow, setIsOverflow] = useState(false);
  useEffect(() => {
    setIsOverflow((ref.current?.offsetWidth || 0) > width);
  }, [ref, width]);

  const [isHeadVisible, setIsHeadVisible] = useState(true);
  useEffect(() => {
    setIsHeadVisible(1 < children.length && (lastItemRef.current?.offsetWidth || 0) < width);
  }, [children.length, lastItemRef, width]);

  const [isTailVisible, setIsTailVisible] = useState(true);
  useEffect(() => {
    setIsTailVisible(2 < children.length && !isOverflow);
  }, [children.length, isOverflow]);

  const [hasTruncation, setHasTruncation] = useState(false);
  useEffect(() => {
    setHasTruncation(
      children.length - ((isTailVisible && 2) || 1) - ((isHeadVisible && 1) || 0) > 0 &&
        (isOverflow || children.length > 4),
    );
  }, [isOverflow, children.length, isTailVisible, isHeadVisible]);

  const [childs, setChilds] = useState<ReactElement[]>([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (0 === children.length) {
      setChilds([]);
      return;
    }

    const arr: ReactElement[] = [];
    if (1 < children.length) {
      const lhs = (isHeadVisible && 1) || 0;
      const rhs = children.length - ((isTailVisible && 3) || 2);

      (hasTruncation &&
        arr.push(
          <div className="relative flex flex-col items-center">
            <Button
              tertiary
              className="fill-gray-500 stroke-gray-500 text-gray-500"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <SVGSolidDotsHorizontal2 className="h-5 w-5" />
            </Button>
            {open && (
              <OptionList className="translate-y-7 whitespace-nowrap">
                {Array.from({ length: 1 + rhs - lhs }, (_, k) => children[k + lhs])
                  .map((e) => (
                    <>
                      <Option
                        value={`${e}`}
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {e}
                      </Option>
                    </>
                  ))
                  .reduce((prev, curr) => (
                    <>
                      {prev}
                      {curr}
                    </>
                  ))}
              </OptionList>
            )}
          </div>,
        )) ||
        (lhs <= rhs &&
          Array.from({ length: children.length - 2 }, (_, k) => children[k + 1]).map((e) =>
            arr.push(<Link color="gray">{e}</Link>),
          ));
    }

    setChilds(arr);
  }, [children, hasTruncation, isHeadVisible, isTailVisible, open, width]);

  return (
    <div ref={ref} className="inline-flex w-fit items-center gap-2">
      {[
        ...Array(isHeadVisible ? 1 : 0).fill(
          <Link color="gray" onClick={children[0].props.onClick}>
            {children[0]}
          </Link>,
        ),
        ...childs,
        ...Array(isTailVisible ? 1 : 0).fill(
          <Link color="gray" onClick={children[children.length - 2].props.onClick}>
            {children[children.length - 2]}
          </Link>,
        ),
        <>
          <button ref={lastItemRef} className="fill-gray-700 stroke-gray-700 text-gray-700">
            {children[children.length - 1]}
          </button>
        </>,
      ].reduce((prev, curr) => (
        <>
          {prev}
          <div className="fill-gray-500 stroke-gray-500 text-gray-500">{separator}</div>
          {curr}
        </>
      ))}
    </div>
  );
};
