import { PropsWithChildren, ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  useDeviceFilterInit,
  useDeviceHistoryCurrentTabInit,
  useDeviceListCategoryInit,
  useDeviceListPagenationInit,
  useDeviceListSortInit,
  useDeviceMemoCategoryInit,
  useDeviceMemoPagenationInit,
  useDeviceMemoSortInit,
  useDeviceOwnerCategoryInit,
  useDeviceOwnerPagenationInit,
  useDeviceOwnerSortInit,
  useDevicePagenationInit,
  useDeviceSearchInit,
  useDeviceSelectedInit,
  useDeviceSortInit,
} from "recoil/device/hooks";
import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGLogo } from "assets/logo.svg";
import { GlobalNavigation } from "components/GlobalNavigation";
import { HeaderLink } from "components/HeaderLink";

import { UserInfo } from "./base.interface";

export const Base = (
  props: PropsWithChildren<
    UserInfo & {
      sidebar: ReactNode;
    }
  >,
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const resetDevicePagenation = useDevicePagenationInit();
  const resetDeviceFilter = useDeviceFilterInit();
  const resetDeviceSearch = useDeviceSearchInit();
  const resetDeviceSelected = useDeviceSelectedInit();
  const resetDeviceSort = useDeviceSortInit();
  const resetDeviceListCategory = useDeviceListCategoryInit();
  const resetDeviceOwnerCategory = useDeviceOwnerCategoryInit();
  const resetDeviceMemoCategory = useDeviceMemoCategoryInit();
  const resetDeviceHistoryTab = useDeviceHistoryCurrentTabInit();
  const resetDeviceListPagenation = useDeviceListPagenationInit();
  const resetDeviceOwnerPagenation = useDeviceOwnerPagenationInit();
  const resetDeviceMemoPagenation = useDeviceMemoPagenationInit();
  const resetDeviceListSort = useDeviceListSortInit();
  const resetDeviceOwnerSort = useDeviceOwnerSortInit();
  const resetDeviceMemoSort = useDeviceMemoSortInit();

  const deviceListInit = () => {
    resetDevicePagenation();
    resetDeviceFilter();
    resetDeviceSearch();
    resetDeviceSelected();
    resetDeviceSort();
  };

  const deviceHistoryInit = () => {
    resetDeviceListCategory();
    resetDeviceOwnerCategory();
    resetDeviceMemoCategory();
    resetDeviceHistoryTab();

    resetDeviceListPagenation();
    resetDeviceOwnerPagenation();
    resetDeviceMemoPagenation();

    resetDeviceListSort();
    resetDeviceOwnerSort();
    resetDeviceMemoSort();
  };

  return (
    <BaseView
      {...props}
      navigate={navigate}
      pathname={location.pathname}
      deviceListInit={deviceListInit}
      deviceHistoryInit={deviceHistoryInit}
    />
  );
};

export const BaseView = (
  props: PropsWithChildren<
    UserInfo & {
      pathname?: string;
      navigate?: (path: string) => void;
      sidebar: ReactNode;
      deviceListInit?: () => void;
      deviceHistoryInit?: () => void;
    }
  >,
) => {
  return (
    <main className="flex min-h-screen flex-col overflow-x-auto">
      <GlobalNavigation
        branding={
          <a href="/">
            <SVGLogo className="h-[.9375rem] w-[6.25rem]" />
          </a>
        }
        className="max-w-[90rem]"
        username={props.username}
        team={props.team}
        permission={props.permission}
      >
        {[
          { label: "시료 관리", path: "/device" },
          { label: "장비 관리", path: "/equipment" },
          { label: "DM 관리", path: "/dm" },
        ].map((e) => (
          <Link
            key={e.path}
            className="contents"
            to={e.path}
            tabIndex={-1}
            onClick={() => {
              e.label !== "시료 관리" && props.deviceListInit?.();
              props.deviceHistoryInit?.();

              props.navigate?.(e.path);
            }}
          >
            <HeaderLink label={<>{e.label}</>} selected={new RegExp(`^${e.path}/*`, "g").test(props.pathname || "")} />
          </Link>
        ))}
        {import.meta.env.DEV && props.permission === "Super admin" && (
          <HeaderLink
            label={<>유저 관리</>}
            selected={/^\/user\/*/.test(props.pathname || "")}
            onClick={() => {
              props.navigate?.("/user");
            }}
          />
        )}
      </GlobalNavigation>
      <div className="mx-auto mt-16 flex w-full max-w-[90rem] flex-1">
        <div className="fixed top-16 z-[500] w-[11.5rem] bg-white">
          {props.sidebar && (
            <div className="h-[calc(100vh-4rem)] w-[11.5rem] overflow-y-auto shadow-base">{props.sidebar}</div>
          )}
        </div>
        <div className={twMerge("flex-1", props.sidebar && "ml-[11.5rem]")}>{props.children}</div>
      </div>
    </main>
  );
};
