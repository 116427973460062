import { atom } from "recoil";

export interface DevilceFilterType {
  key: string;
  value: string | null;
}

export interface DeviceSelectedType {
  serialNumber: string;
}

interface DeviceFiltersType {
  [i: string]: DevilceFilterType[];
  model: DevilceFilterType[];
  formFactor: DevilceFilterType[];
  capacity: DevilceFilterType[];
  location: DevilceFilterType[];
  stage: DevilceFilterType[];
  customer: DevilceFilterType[];
  grade: DevilceFilterType[];
  organization: DevilceFilterType[];
  owner: DevilceFilterType[];
}

interface DeviceOrderbyType {
  [key: string]: number;
  createdAt: number;
  serialNumber: number;
  model: number;
  formFactor: number;
  hwRev: number;
  capacity: number;
  stage: number;
  customer: number;
  organization: number;
  owner: number;
  note: number;
}

export const devicePagenationState = atom({
  key: "devicePagenationState",
  default: {
    perPage: 10,
    current: 1,
  },
});

export const deviceFilterState = atom<DeviceFiltersType>({
  key: "deviceFilterState",
  default: {
    model: [] as DevilceFilterType[],
    formFactor: [] as DevilceFilterType[],
    capacity: [] as DevilceFilterType[],
    location: [] as DevilceFilterType[],
    stage: [] as DevilceFilterType[],
    customer: [] as DevilceFilterType[],
    grade: [] as DevilceFilterType[],
    organization: [] as DevilceFilterType[],
    owner: [] as DevilceFilterType[],
  },
});

export const deviceSearchState = atom({
  key: "deviceSearchState",
  default: "",
});

export const deviceSortState = atom({
  key: "deviceSortState",
  default: {
    createdAt: 2,
    serialNumber: 0,
    model: 0,
    formFactor: 0,
    hwRev: 0,
    capacity: 0,
    stage: 0,
    customer: 0,
    organization: 0,
    owner: 0,
    note: 0,
  } as DeviceOrderbyType,
});

export const deviceSelectedState = atom({
  key: "deviceSelectedState",
  default: [] as DeviceSelectedType[],
});

export const deviceListCategoryState = atom<"" | "시료 등록" | "시료 편집" | "시료 삭제">({
  key: "deviceListCategoryState",
  default: "",
});

export const deviceOwnerCategoryState = atom<"" | "소유자 등록" | "소유자 변경">({
  key: "deviceOwnerCategoryState",
  default: "",
});

export const deviceMemoCategoryState = atom<"" | "메모 등록" | "메모 삭제">({
  key: "deviceMemoCategoryState",
  default: "",
});

export const deviceHistoryCurrentTabState = atom({
  key: "deviceHistoryCurrentTabState",
  default: 0,
});

export const deviceListPagenationState = atom({
  key: "deviceListPagenationState",
  default: {
    perPage: 10,
    current: 1,
  },
});

export const deviceOwnerPagenationState = atom({
  key: "deviceOwnerPagenationState",
  default: {
    perPage: 10,
    current: 1,
  },
});

export const deviceMemoPagenationState = atom({
  key: "deviceMemoPagenationState",
  default: {
    perPage: 10,
    current: 1,
  },
});

export const deviceListSortState = atom({
  key: "deviceListSortState",
  default: 0,
});

export const deviceOwnerSortState = atom({
  key: "deviceOwnerSortState",
  default: 0,
});

export const deviceMemoSortState = atom({
  key: "deviceMemoSortState",
  default: 0,
});
