import { twMerge } from "tailwind-merge";

import { Option } from "components/Select";
import { OptionList } from "components/Select/OptionList";
import { auth } from "services/entraid";

export const SettingMenu = (props: { className?: string; username: string; team: string; permission?: string }) => {
  return (
    <OptionList className={twMerge("gap-1 whitespace-nowrap", props.className)}>
      <Option className="border-b border-b-gray-300">
        {props.username}
        <div className="flex gap-1 whitespace-nowrap text-gray-500">
          <div>{props.team}</div>
          {props.permission && ["/", props.permission].map((e, i) => <p key={i}>{e}</p>)}
        </div>
      </Option>
      <Option
        onClick={() => {
          auth.signOut().catch(console.error);
        }}
      >
        <p className="text-red-500">로그아웃</p>
      </Option>
    </OptionList>
  );
};
