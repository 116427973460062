import React, { ReactNode } from "react";

import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  children?: ReactNode;
  size?: string;
  information?: boolean;
  error?: boolean;
  warning?: boolean;
  success?: boolean;
  neutral?: boolean;
}

export const Badge: React.FC<Props> = (props) => {
  const { className, children, size, neutral, success, warning, error, information, ...etcs } = props;

  return (
    <div
      className={twMerge(
        "flex items-start justify-start",
        "rounded-sm font-noto-sans font-medium",
        "w-fit py-0.5",
        neutral && "bg-gray-200 fill-gray-200 stroke-gray-200 text-gray-800",
        success && "bg-emerald-200 fill-emerald-200 stroke-emerald-200 text-emerald-800",
        warning && "bg-amber-200 fill-amber-200 stroke-amber-200 text-amber-800",
        error && "bg-red-200 fill-red-200 stroke-red-200 text-red-800",
        information && "bg-blue-200 fill-blue-200 stroke-blue-200 text-blue-800",
        size == "m" && "px-3 text-sm",
        size == "s" && "px-2 text-xs",
        className,
      )}
      {...etcs}
    >
      {children}
    </div>
  );
};

Badge.defaultProps = {
  size: "s",
};
