import React, { ReactNode } from "react";

import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  selected?: boolean;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  danger?: boolean;
  warning?: boolean;
  dashed?: boolean;
}
export const Button: React.FC<Props> = (props) => {
  const { className, children, onClick, selected, primary, secondary, tertiary, danger, warning, dashed, ...etcs } =
    props;

  return (
    <button
      onClick={props.disabled ? undefined : onClick}
      onBlur={props.disabled ? undefined : props.onBlur}
      className={twMerge(
        "flex items-center justify-center gap-2",
        "font-medium",
        "rounded-sm",
        primary &&
          [
            selected ? "bg-primary-700 disabled:bg-primary-700/30" : "bg-primary-500 disabled:bg-primary-500/30",
            "fill-white stroke-white text-white disabled:fill-white/30 disabled:stroke-white/30 disabled:text-white/30",
            "enabled:hover:bg-primary-600 enabled:focus:bg-primary-600",
            "enabled:active:bg-primary-700",
          ].join(" "),
        (secondary || dashed) &&
          [
            selected
              ? "bg-gray-50 outline-gray-500 disabled:bg-gray-50/30 disabled:outline-gray-500/30"
              : "bg-white outline-gray-300 disabled:bg-white/30 disabled:outline-gray-300/30",
            "disabled:fill-gray-900/30 disabled:stroke-gray-900/30 disabled:text-gray-900/30",
            dashed ? "outline-dashed" : "outline",
            "outline-1",
            "enabled:hover:bg-white enabled:hover:outline-gray-400 enabled:focus:bg-gray-50 enabled:focus:outline-gray-500",
            "enabled:active:bg-gray-50 enabled:active:outline-gray-500",
          ].join(" "),
        tertiary &&
          [
            selected
              ? "bg-primary-50 fill-primary-500 stroke-primary-500 text-primary-500 disabled:bg-primary-50/30 disabled:fill-primary-500/30 disabled:stroke-primary-500/30 disabled:text-primary-500/30"
              : "disabled:fill-gray-900/30 disabled:stroke-gray-900/30 disabled:text-gray-900/30",
            "enabled:hover:bg-gray-100",
            "enabled:active:bg-primary-50 enabled:active:fill-primary-500 enabled:active:stroke-primary-500 enabled:active:text-primary-500",
          ].join(" "),
        danger &&
          [
            selected ? "bg-red-700 disabled:bg-red-700/30" : "bg-red-500 disabled:bg-red-500/30",
            "fill-white stroke-white text-white disabled:fill-white/30 disabled:stroke-white/30 disabled:text-white/30",
            "enabled:hover:bg-red-600 enabled:focus:bg-red-600",
            "enabled:active:bg-red-700",
          ].join(" "),
        warning &&
          [
            selected ? "bg-amber-700 disabled:bg-amber-700/30" : "bg-amber-500 disabled:bg-amber-500/30",
            "fill-white stroke-white text-white disabled:fill-white/30 disabled:stroke-white/30 disabled:text-white/30",
            "enabled:hover:bg-amber-600 enabled:focus:bg-amber-600",
            "enabled:active:bg-amber-700",
          ].join(" "),
        className,
      )}
      {...etcs}
    >
      {children}
    </button>
  );
};
