import React, { ReactElement, ReactNode, useState } from "react";

import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGSolidCheveronDown } from "assets/icons/Solid/cheveron-down.svg";
import { ReactComponent as SVGSolidCheveronUp } from "assets/icons/Solid/cheveron-up.svg";

export interface Props {
  iid?: string;
  children?: ReactElement<Props>[];
  title?: ReactNode;
  header?: boolean;
  sep?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  collapsable?: boolean;
  open?: boolean;
  selected?: string;
  indent?: number;
}
export const SidebarItem = (props: Props) => {
  const { children, title, header, sep, onClick, collapsable, selected, indent, ...tails } = props;

  const [open, setOpen] = useState(props.open);

  return (
    <>
      <button
        className={twMerge(
          "flex w-full gap-2",
          !header && "enabled:hover:bg-gray-100 disabled:opacity-30",
          !header && selected?.search(props.iid || "bug") !== -1 && "bg-primary-50",
        )}
        disabled={header}
        onClick={(ev) => {
          collapsable ? setOpen(!open) : onClick?.(ev);
        }}
        {...tails}
      >
        <div className="flex w-full gap-2 pr-4" style={{ paddingLeft: `${1 + (indent || 0)}rem` }}>
          <div
            className={twMerge(
              "flex flex-1 gap-2 text-left",
              header ? "py-3 text-xs text-gray-700" : "py-3.5 text-sm",
              header && sep && "border-t border-t-gray-300",
              selected?.search(props.iid || "bug") !== -1 && " fill-primary-500 stroke-primary-500 text-primary-500",
            )}
          >
            {title}
          </div>
          {collapsable && (
            <button
              className="fill-gray-500 stroke-gray-500"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <SVGSolidCheveronUp className="h-5 w-5" /> : <SVGSolidCheveronDown className="h-5 w-5" />}
            </button>
          )}
        </div>
      </button>
      {open && (
        <div className="flex w-full flex-col items-start">
          {children?.map((e) => {
            const { iid } = e.props;
            return React.cloneElement(e, {
              ...e.props,
              key: iid,
              selected,
              indent: 1 + (indent || 0),
              onClick: async (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.props.onClick?.(evt);
              },
            });
          })}
        </div>
      )}
    </>
  );
};
