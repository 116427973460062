import { PropsWithChildren } from "react";

import { RouterFunction } from "shared/RouterFunction.interface";

import { Sidebar, SidebarItem } from "components/Sidebar";

import { Base } from "./base";
import { UserInfo } from "./base.interface";

const DeviceSidebar = (props: RouterFunction & { deviceListInit?: () => void; deviceHistoryInit?: () => void }) => {
  return (
    <Sidebar selected={props.location?.pathname}>
      <SidebarItem iid={"manage_device"} title={<>시료 관리</>} header />
      <SidebarItem
        iid={"/device/list"}
        title={<>시료 목록</>}
        onClick={() => {
          props.navigate?.("/device/list");
          props.deviceHistoryInit?.();
        }}
      />
      <SidebarItem
        iid={"/device/transfer/bulk"}
        title={<>엑셀 소유자 변경</>}
        onClick={() => {
          props.navigate?.("/device/transfer/bulk/form");
          props.deviceListInit?.();
          props.deviceHistoryInit?.();
        }}
      />
      <SidebarItem
        iid={"/device/history"}
        title={<>히스토리</>}
        onClick={() => {
          props.navigate?.("/device/history");
          props.deviceListInit?.();
        }}
      />
    </Sidebar>
  );
};

export const DeviceLayoutView = (
  props: PropsWithChildren<UserInfo> & RouterFunction & { deviceListInit?: () => void; deviceHistoryInit?: () => void },
) => {
  return (
    <Base
      username={props.username}
      team={props.team}
      permission={props.permission}
      sidebar={
        <DeviceSidebar
          navigate={props.navigate}
          location={props.location}
          deviceListInit={props.deviceListInit}
          deviceHistoryInit={props.deviceHistoryInit}
        />
      }
    >
      {props.children}
    </Base>
  );
};
