import { graphql, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { sessionState } from "SessionRoute";

import { DetailLayoutEquipmentQuery } from "./__generated__/DetailLayoutEquipmentQuery.graphql";
import { DetailLayoutView } from "./DetailLayoutView";

const queryEquipInfo = graphql`
  query DetailLayoutEquipmentQuery($input: EquipmentInput!) {
    equipment(input: $input) {
      eid
      name
      dm
      issues {
        iid
        solvedAt
      }
    }
  }
`;

export const DetailLayout = () => {
  const { id: eid } = useParams();
  const currentSession = useRecoilValue(sessionState);

  const info = useLazyLoadQuery<DetailLayoutEquipmentQuery>(queryEquipInfo, { input: { eid } })["equipment"];

  return (
    <DetailLayoutView
      info={{
        eid: info.eid,
        name: info.name,
        dm: info.dm,
        unresolved: info.issues.some((obj) => obj.solvedAt === null),
      }}
      permission={currentSession?.permission?.name || ""}
    />
  );
};
