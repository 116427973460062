import { MouseEventHandler, ReactNode } from "react";

import { twMerge } from "tailwind-merge";

import { ReactComponent as SVGSolidCheck } from "assets/icons/Solid/check.svg";

export interface Props {
  children?: ReactNode;
  className?: string;
  checked?: boolean;
  icon?: ReactNode;
  onClick?: MouseEventHandler;
  value?: string;
  disabled?: boolean;
  selected?: boolean;
}
export const Option = (props: Props) => {
  const { children, className, checked, icon, ...tails } = props;
  const _icon = icon || <SVGSolidCheck className="h-5 w-5" />;

  return (
    <button className={twMerge(`group p-1 focus-visible:outline-none disabled:opacity-30`, className)} {...tails}>
      <div
        className={twMerge(
          "flex gap-2 rounded-sm bg-white p-2",
          "group-hover:bg-gray-100",
          "group-enabled:group-focus-visible:fill-primary-500 group-enabled:group-focus-visible:stroke-primary-500 group-enabled:group-focus-visible:text-primary-500",
          checked && "fill-primary-500 stroke-primary-500 font-medium text-primary-500",
        )}
      >
        <div className="flex-1 text-left">{children}</div>
        {checked && _icon}
      </div>
    </button>
  );
};
