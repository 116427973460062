import { useEffect, useRef } from "react";
import { graphql } from "react-relay";
import { Navigate, Outlet } from "react-router-dom";

import { atom, useRecoilState } from "recoil";

import { SessionRouteUserQuery } from "__generated__/SessionRouteUserQuery.graphql";
import { fetchQuery } from "relay-runtime";
import { SessionType } from "shared/Session.interface";

import { Spinner } from "components/Spinner";
import { auth } from "services/entraid";

import RelayEnvironment from "./libs/RelayEnvironment";

const userQuery = graphql`
  query SessionRouteUserQuery {
    user230227 {
      id
      meta {
        name
      }
      orgs {
        id
        name
      }
      permissions {
        name
      }
    }
  }
`;

export const sessionState = atom<SessionType | undefined | null>({
  key: "sessionState",
  default: null,
});

export const SessionRoute = (props: { signin?: boolean; signup?: boolean }) => {
  const [curSession, setCurSession] = useRecoilState(sessionState);
  const callOnce = useRef(false);
  useEffect(() => {
    if (callOnce.current) return;
    callOnce.current = true;

    auth.onAuthStateChanged(() => {
      auth.getAccount().then(function (user) {
        if (user) {
          fetchQuery<SessionRouteUserQuery>(RelayEnvironment, userQuery, {}).subscribe({
            next: (res) => {
              const data = res.user230227;

              if (data === null) {
                if (user?.name) {
                  setCurSession({
                    uid: user?.homeAccountId,
                    meta: { name: user?.name },
                    team: null,
                    permission: undefined,
                  });
                }
                return;
              }

              setCurSession({
                uid: data.id,
                meta: data.meta,
                team: { tid: data.orgs[0].id, name: [data.orgs[0].name[1], data.orgs[0].name[2]].join(" / ") },
                permission: data.permissions[0],
              });
            },
            error: () => {
              if (user?.name) {
                setCurSession({
                  uid: user?.homeAccountId,
                  meta: { name: user?.name },
                  team: null,
                  permission: undefined,
                });
              }
            },
          });
        } else {
          setCurSession(undefined);
        }
      });
    });
  });

  if (props.signin) {
    return curSession === undefined ? (
      <Outlet />
    ) : curSession?.team !== null ? (
      <Navigate to="/" replace />
    ) : (
      <Navigate to="/signup" replace />
    );
  } else if (props.signup) {
    return curSession === undefined ? (
      <Navigate to="signin" replace />
    ) : curSession?.team === null ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace />
    );
  } else {
    return curSession !== null ? (
      curSession ? (
        curSession.team !== null ? (
          <Outlet />
        ) : (
          <Navigate to="/signup" replace />
        )
      ) : (
        <Navigate to="/signin" replace />
      )
    ) : (
      <div className="grid h-screen w-screen items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }
};
