import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { SessionType } from "shared/Session.interface";

import { DMLayout } from "layouts/";

const DMHistory = lazy(() => import("pages/dm/history").then((module) => ({ default: module.History })));
const DMList = lazy(() => import("pages/dm/list").then((module) => ({ default: module.List })));
const DMAddIssueModal = lazy(() =>
  import("pages/dm/list/detail/AddIssueModal").then((module) => ({ default: module.AddIssueModal })),
);
const DMDeleteModal = lazy(() =>
  import("pages/dm/list/detail/DeleteModal").then((module) => ({ default: module.DeleteModal })),
);
const DMDetail = lazy(() => import("pages/dm/list/detail").then((module) => ({ default: module.Detail })));
const DMEditModal = lazy(() =>
  import("pages/dm/list/detail/EditModal").then((module) => ({ default: module.EditModal })),
);
const DMIssueModal = lazy(() => import("pages/dm/list/detail").then((module) => ({ default: module.DMIssueModal })));
const DMRegister = lazy(() => import("pages/dm/list/register").then((module) => ({ default: module.Register })));
const DMRegisterConfirmModal = lazy(() =>
  import("pages/dm/list/register/ConfirmModal").then((module) => ({ default: module.ConfirmModal })),
);
const DMRegisterErrorModal = lazy(() =>
  import("pages/dm/list/register/ErrorModal").then((module) => ({ default: module.ErrorModal })),
);

export const DMRoute = (props: { background?: Location; session: SessionType | null | undefined }) => {
  return [
    <Route
      key="dm"
      path="/dm"
      element={
        <DMLayout>
          <Outlet />
        </DMLayout>
      }
    >
      <Route index element={<Navigate to="list" replace />} />
      <Route path="list">
        <Route
          index
          element={
            <Suspense>
              <DMList />
            </Suspense>
          }
        />
        <Route path="register" element={<DMRegister />}>
          {props.background && <Route path="confirm" element={<DMRegisterConfirmModal />} />}
          {props.background && <Route path="error" element={<DMRegisterErrorModal />} />}
          <Route path="*" element={<Navigate to="./" replace />} />
        </Route>
        <Route path="detail/:sn" element={<DMDetail />}>
          {props.background && <Route path="edit" element={<DMEditModal />} />}
          {props.background && <Route path="delete" element={<DMDeleteModal />} />}
          {props.background && <Route path="add-issue" element={<DMAddIssueModal />} />}
          {props.background && (
            <Route path="issue" element={<DMIssueModal permission={props.session.permission?.name} />} />
          )}
          <Route path="*" element={<Navigate to="../" replace />} />
        </Route>
      </Route>
      <Route path="history" element={<DMHistory />} />
    </Route>,
  ];
};
